import styles from './BoxWithRoundedCorners.css'

export function BoxWithRoundedCorners({ children }) {
  return (
    <div className={styles.component}>
      <div role="presentation" className={styles.top} />
      <div className={styles.content}>{children}</div>
      <div role="presentation" className={styles.bottom} />
    </div>
  )
}
