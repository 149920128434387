import { ImageCover }  from '/features/buildingBlocks/Image'
import { HeadingXl } from '/features/buildingBlocks/Heading'
import styles from './Hero.css'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { BoxWithRoundedCorners } from '/features/buildingBlocks/boxWithRoundedCorners/BoxWithRoundedCorners'
import { BackgroundVideo } from '/features/buildingBlocks/BackgroundVideo'
import { Icon } from '/features/buildingBlocks/Icon'
import play from '/images/icons/play.raw.svg'
import pause from '/images/icons/pause.raw.svg'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { formatDate } from '/machinery/formatDate'
import { useMediaQuery } from '@kaliber/use-media-query'
import { viewportMd } from '/cssGlobal/media.css'
import  { animated, useSpring, config } from 'react-spring'

export function HeroColor({ title, color, subtitle = undefined, dates = undefined }) {
  return (
    <BoxWithRoundedCorners>
      <header className={styles.componentColor} data-style-context={color} data-x='hero'>
        <div className={styles.layout}>
          <ContainerMd>
            <div className={styles.headingAndDates}>
              <HeadingXl h='1' {...{ title }} layoutClassName={styles.colorTitle} />
              <Dates {... { dates, subtitle }} />
            </div>
          </ContainerMd>
        </div>
      </header>
    </BoxWithRoundedCorners>
  )
}

export function HeroImage({ image, title, dates = undefined, subtitle = undefined }) {
  return (
    <BoxWithRoundedCorners>
      <header className={styles.componentImage} data-x='hero'>
        <div className={styles.layout}>
          <ContainerMd>
            <div className={styles.headingAndDates}>
              <HeadingXl h='1' {...{ title }} layoutClassName={styles.imageTitle} />
              <Dates {... { dates, subtitle }} />
            </div>
          </ContainerMd>
        </div>
        <div className={styles.coverImage}>
          <ImageCover {...{ image }} layoutClassName={styles.image} aspectRatio={16 / 9} sizes='100vw' />
        </div>
      </header>
    </BoxWithRoundedCorners>
  )
}

export function HeroVideo({ image, title, videoBackgroundSources }) {
  const { __ } = useTranslate()
  const [buttonProps, buttonSpringApi] = useSpring(() => ({ opacity: 0, scale: 0.5, x: 150, y: 250, config: config.gentle }))
  const [backgroundVideoActive, setBackgroundVideoActive] = React.useState(false)
  const wrapperRef = React.useRef(null)
  const buttonRef = React.useRef(null)
  const isViewportMd = useMediaQuery(viewportMd)
  const videoRef = React.useRef(null)

  return (
    <div
      className={styles.componentVideo}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      <div className={styles.layout} ref={wrapperRef}>
        {title && (
          <ContainerMd>
            <HeadingXl h='1' {...{ title }} layoutClassName={cx(styles.videoTitle, backgroundVideoActive && styles.hide)} />
          </ContainerMd>
        )}
        <button
          type="button"
          aria-label={__`hero-video-button-label`}
          className={styles.videoClickableArea}
          onClick={handleChange}
        >
          <animated.div className={styles.animatedButton} style={isViewportMd ? buttonProps : null} ref={buttonRef}>
            <PlayIcon active={backgroundVideoActive} />
          </animated.div>
        </button>
      </div>
      {image && (
        <div className={styles.coverImage}>
          <ImageCover {...{ image }}  layoutClassName={styles.image}  aspectRatio={16 / 9} sizes='100vw' />
        </div>
      )}
      <BackgroundVideo
        sources={videoBackgroundSources}
        layoutClassName={cx(styles.video, backgroundVideoActive && styles.active)}
        {... { videoRef }}
        playing={backgroundVideoActive}
      />
    </div>
  )

  function handleChange() {
    setBackgroundVideoActive(x => !x)
  }

  function handleMouseMove(e) {
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const buttonRect = buttonRef.current.getBoundingClientRect()
    const x = e.clientX - wrapperRect.x - buttonRect.width / 2
    const y = e.clientY - wrapperRect.y - buttonRect.height / 2
    buttonSpringApi.start({ opacity: 1, scale: 1, x, y })
  }

  function handleMouseLeave() {
    const wrapperRect = wrapperRef.current.getBoundingClientRect()
    const buttonRect = buttonRef.current.getBoundingClientRect()
    const x = wrapperRect.width / 2 - buttonRect.width / 2
    const y = wrapperRect.height / 2 - buttonRect.height / 2
    buttonSpringApi.start({ x, y })
  }
}

function PlayIcon({ active }) {
  return (
    <div
      className={styles.componentPlayIcon}
    >
      <div className={styles.icon}>
        <Icon icon={active ? pause : play} />
      </div>
    </div>
  )
}

function DateBlock({ date }) {
  return (
    <div className={styles.componentDateBlock}>
      {formatDate(date)}
    </div>
  )
}

function Dates({ subtitle = undefined, dates = undefined }) {
  if (subtitle) {
    return (
      <div className={cx(styles.componentDates, styles.dateText)}>
        {subtitle}
      </div>
    )
  }
  else if (dates) {
    return (
      <div className={styles.componentDates}>
        {dates.map((date) => (
          <DateBlock key={date._key} date={date.activityDate} />
        ))}
      </div>
    )
  }
  return null
}
